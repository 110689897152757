.form-group {
    margin-bottom: 0 !important;
}

label{
    margin-bottom: 0 !important;
}

* {
    -webkit-border-radius: 0 !important;
       -moz-border-radius: 0 !important;
            border-radius: 0 !important;
  }

  td{
    text-align: left;     
  }

  th{
    text-align: left; 

  }

  .btn:focus {
    box-shadow: none;
  }

  .svg-inline--fa{
      cursor: pointer;
  }

  .iconcell{
    width: 90px !important;   
}

input[type="file"] {
  display: none;
}

.card-header{
  font-family: "Chivo",Serif; 
  font-weight: 1000 !important;
  color: #004976; 
  -webkit-font-smoothing: antialiased;
}

.bankButtons{
 width: 100%;
}




